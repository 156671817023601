/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.2
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table,
  Progress,
  Row,
  Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import {
  dashboardPanelChart,
  dashboardActiveUsersChart,
  dashboardSummerChart,
  dashboardActiveCountriesCard,
} from "variables/charts.js";

import jacket from "assets/img/saint-laurent.jpg";
import shirt from "assets/img/balmain.jpg";
import swim from "assets/img/prada.jpg";

import { table_data } from "variables/general.js";

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

function Dashboard() {
  const createTableData = () => {
    var tableRows = [];
    for (var i = 0; i < table_data.length; i++) {
      tableRows.push(
        <tr key={i}>
          <td>
            <div className="flag">
              <img src={table_data[i].flag} alt="us_flag" />
            </div>
          </td>
          <td>{table_data[i].country}</td>
          <td className="text-right">{table_data[i].count}</td>
          <td className="text-right">{table_data[i].percentage}</td>
        </tr>
      );
    }
    return tableRows;
  };
  return (
    <>
      <PanelHeader
        size="lg"
        content={
          <Line
            data={dashboardPanelChart.data}
            options={dashboardPanelChart.options}
          />
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card className="card-stats card-raised">
              <CardBody>
                <Row>
                  <Col md="3">
                    <div className="statistics">
                      <div className="info">
                        <div className="icon icon-primary">
                          <i className="now-ui-icons ui-2_chat-round" />
                        </div>
                        <h3 className="info-title">859</h3>
                        <h6 className="stats-title">Messages</h6>
                      </div>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="statistics">
                      <div className="info">
                        <div className="icon icon-success">
                          <i className="now-ui-icons business_money-coins" />
                        </div>
                        <h3 className="info-title">
                          <small>$</small>
                          3,521
                        </h3>
                        <h6 className="stats-title">Today Revenue</h6>
                      </div>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="statistics">
                      <div className="info">
                        <div className="icon icon-info">
                          <i className="now-ui-icons users_single-02" />
                        </div>
                        <h3 className="info-title">562</h3>
                        <h6 className="stats-title">Customers</h6>
                      </div>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="statistics">
                      <div className="info">
                        <div className="icon icon-danger">
                          <i className="now-ui-icons objects_support-17" />
                        </div>
                        <h3 className="info-title">353</h3>
                        <h6 className="stats-title">Support Requests</h6>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Active Users</h5>
                <CardTitle tag="h2">34,252</CardTitle>
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-round btn-icon"
                    color="default"
                    outline
                  >
                    <i className="now-ui-icons loader_gear" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>Action</DropdownItem>
                    <DropdownItem>Another Action</DropdownItem>
                    <DropdownItem>Something else here</DropdownItem>
                    <DropdownItem className="text-danger">
                      Remove data
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={dashboardActiveUsersChart.data}
                    options={dashboardActiveUsersChart.options}
                  />
                </div>
                <Table responsive>
                  <tbody>{createTableData()}</tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <div className="stats">
                  <i className="now-ui-icons arrows-1_refresh-69" />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Summer Email Campaign</h5>
                <CardTitle tag="h2">55,300</CardTitle>
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-round btn-icon"
                    color="default"
                    outline
                  >
                    <i className="now-ui-icons loader_gear" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>Action</DropdownItem>
                    <DropdownItem>Another Action</DropdownItem>
                    <DropdownItem>Something else here</DropdownItem>
                    <DropdownItem className="text-danger">
                      Remove data
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={dashboardSummerChart.data}
                    options={dashboardSummerChart.options}
                  />
                </div>
                <div className="card-progress">
                  <div className="progress-container">
                    <span className="progress-badge">Delivery Rate</span>
                    <Progress max="100" value="90">
                      <span className="progress-value">90%</span>
                    </Progress>
                  </div>
                  <div className="progress-container progress-success">
                    <span className="progress-badge">Open Rate</span>
                    <Progress max="100" value="60">
                      <span className="progress-value">60%</span>
                    </Progress>
                  </div>
                  <div className="progress-container progress-info">
                    <span className="progress-badge">Click Rate</span>
                    <Progress max="100" value="12">
                      <span className="progress-value">12%</span>
                    </Progress>
                  </div>
                  <div className="progress-container progress-warning">
                    <span className="progress-badge">Hard Bounce</span>
                    <Progress max="100" value="5">
                      <span className="progress-value">5%</span>
                    </Progress>
                  </div>
                  <div className="progress-container progress-danger">
                    <span className="progress-badge">Spam Report</span>
                    <Progress max="100" value="0.11">
                      <span className="progress-value">0.11%</span>
                    </Progress>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <div className="stats">
                  <i className="now-ui-icons arrows-1_refresh-69" />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Active Countries</h5>
                <CardTitle tag="h2">105</CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={dashboardActiveCountriesCard.data}
                    options={dashboardActiveCountriesCard.options}
                  />
                </div>
                <VectorMap
                  map={"world_mill"}
                  backgroundColor="transparent"
                  zoomOnScroll={false}
                  containerStyle={{
                    width: "100%",
                    height: "280px",
                  }}
                  containerClassName="map"
                  regionStyle={{
                    initial: {
                      fill: "#e4e4e4",
                      "fill-opacity": 0.9,
                      stroke: "none",
                      "stroke-width": 0,
                      "stroke-opacity": 0,
                    },
                  }}
                  series={{
                    regions: [
                      {
                        values: mapData,
                        scale: ["#AAAAAA", "#444444"],
                        normalizeFunction: "polynomial",
                      },
                    ],
                  }}
                />
              </CardBody>
              <CardFooter>
                <div className="stats">
                  <i className="now-ui-icons ui-2_time-alarm" />
                  Last 7 days
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Best Selling Products</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive className="table-shopping">
                  <thead>
                    <tr>
                      <th className="text-center" />
                      <th>PRODUCT</th>
                      <th>COLOR</th>
                      <th>Size</th>
                      <th className="text-right">PRICE</th>
                      <th className="text-right">QTY</th>
                      <th className="text-right">AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="img-container">
                          <img src={jacket} alt="..." />
                        </div>
                      </td>
                      <td className="td-name">
                        <a href="#jacket">Suede Biker Jacket</a>
                        <br />
                        <small>by Saint Laurent</small>
                      </td>
                      <td>Black</td>
                      <td>M</td>
                      <td className="td-number">
                        <small>€</small>3,390
                      </td>
                      <td className="td-number">1</td>
                      <td className="td-number">
                        <small>€</small>549
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="img-container">
                          <img src={shirt} alt="..." />
                        </div>
                      </td>
                      <td className="td-name">
                        <a href="#shirt">Jersey T-Shirt</a>
                        <br />
                        <small>by Balmain</small>
                      </td>
                      <td>Black</td>
                      <td>M</td>
                      <td className="td-number">
                        <small>€</small>499
                      </td>
                      <td className="td-number">2</td>
                      <td className="td-number">
                        <small>€</small>998
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="img-container">
                          <img src={swim} alt="..." />
                        </div>
                      </td>
                      <td className="td-name">
                        <a href="#pants">Slim-Fit Swim Short </a>
                        <br />
                        <small>by Prada</small>
                      </td>
                      <td>Red</td>
                      <td>M</td>
                      <td className="td-number">
                        <small>€</small>200
                      </td>
                      <td className="td-number">3</td>
                      <td className="td-number">
                        <small>€</small>799
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5" />
                      <td className="td-total">Total</td>
                      <td className="td-price">
                        <small>€</small>2,346
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
