
import React from "react";
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import TypebotChat from "components/watiobot/typebot.js";
import ChartComponent from "components/watiobot/ChartComponent.js";
import "assets/css/screens/dashboard-page.css";

function DashboardPage() {

    return (
        <>
          <PanelHeader size="sm" />
          <div className="content">
            <Row className="scrollable-row">
              <Col xs="12" sm="12" md="4">
                <Card className="chatbotCard">
                <CardHeader tag={"h5"}><p>Habla con tus facturas</p></CardHeader>
                  <TypebotChat className="pt-2"/>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="8">
                {/* */}
                <Card>
                  <CardHeader tag={"h5"}><p>Tu resumen de datos</p></CardHeader>
                  <CardBody className="botCard" style={{ overscrollBehavior: "contain"}}>
                    <ChartComponent />
                  </CardBody>
                </Card>
    
                
              </Col>
              
            </Row>
          </div>
        </>
      );
}

export default DashboardPage;
