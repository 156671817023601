import { Standard } from '@typebot.io/react';
import {} from '@typebot.io/js';
const apiHost=process.env.REACT_APP_TYPEBOT_API_HOST||'https://bot.greenskills.es';
const typebot=process.env.REACT_APP_TYPEBOT_BOT_ID||"piloto";
const TypebotChat = () => {
    
    return  <Standard
    apiHost={apiHost}
      typebot={typebot}

      hiddenFields={{ myParameter: "value" }}
    />
  };
  export default TypebotChat;