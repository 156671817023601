import React, { useState } from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, FormGroup, Label, Input, Button, Row, Col, Card } from 'reactstrap'; // Import Form components from reactstrap
import { Document, Page,pdfjs } from 'react-pdf';
import "assets/css/screens/invoice-processor-page.css"
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'providers/authProvider2';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

var apiEndpoint=process.env.REACT_APP_AI_CORE_API_ENDPOINT||'http://api.watiobot.ai';

const api = axios.create({
    baseURL: apiEndpoint,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods':"POST",
    },
  });

const PdfUploader = () => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null); // Nuevo estado para la URL del objeto  
  const [data, setData] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Nuevo estado para rastrear la carga
  const [message, setMessage] = useState(null);
  const [isSaving, setIsSaving] = useState(false); // Nuevo estado para rastrear el guardado
  const [error, setError] = useState(null); // Nuevo estado para rastrear los errores
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [showInputs, setShowInputs] = useState(false);
  const [accessToken] = useState(() => sessionStorage.getItem('access_token'));
  const { clearTokenFromSessionStorage } = useAuth();
  const navigate = useNavigate();
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  
  function goPrevPage() {
    setPageNumber(pageNumber - 1);
  }
  
  function goNextPage() {
    setPageNumber(pageNumber + 1);
  }
  const handleFileChange = (e) => {
    console.log(e.target.files); // Verificar si el archivo se está cargando correctamente
    setFile(e.target.files[0]);
    const url = URL.createObjectURL(e.target.files[0]);
    console.log(url); // Verificar si la URL del objeto se está creando correctamente
    setFileUrl(url);
  };
  const handleUpload = async () => {
    setIsLoading(true); // Comienza la carga
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await api.post('process_pdf', formData,{
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      } );
      console.log(response);
      const jsonData = JSON.parse(response.data);
      const jsonObject=jsonData[0]
      setData(jsonObject);
      setShowInputs(true);
      setIsEditable(true);
      setError(null);
    } catch (error) {
      setError('Error interno del servidor, prueba por favor a repetir la operación');
      if(error.response != null && error.response.status == 401){
        clearTokenFromSessionStorage();
        navigate("/auth/login-page");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setShowInputs(true);
    console.log(data);
  };

  const handleSave = async () => {
    setError(null);
    setIsSaving(true);
    const stringifiedData = JSON.stringify(data);
    const requestBody = { jsonInvoice: stringifiedData };
    console.log(requestBody);
    try {
      const response = await api.post('direct-persist-invoice', requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      setMessage(response.data.message); // Guarda el mensaje de respuesta
      setIsEditable(false);
      setError(null);
    } catch (error) {
      setError('Error interno del servidor, prueba por favor a repetir la operación');
      if(error.response != null && error.response.status == 401){
        clearTokenFromSessionStorage();
        navigate("/auth/login-page");
      }
    } finally {
      setIsSaving(false);
    }
    setFileUrl(null); // Limpia el visor PDF
    setTimeout(() => {
      setMessage(null); // Oculta el mensaje después de 4 segundos
      // Restablece el estado inicial del componente
      setFile(null);
      setData({});
      setShowInputs(false);
    }, 4000);
  };

  const showSpinner = isLoading || isSaving;
  const textSpinner = isLoading
    ? "Estamos procesando tu archivo, por favor espera..."
    : "Por favor, espera un momento...";

  return (
    <div className='pdf-container'>
      <Row>
        {!isEditable && (
          <Col lg={6} md={6} sm={12} xs={12} className="load-file-container">
            <div>
              <p>Selecciona tu factura</p>
              <input 
                type="file" 
                className="form-file-upload form-file-simple" 
                onChange={handleFileChange}
                style={{
                  border: '2px dashed #ccc',
                  borderRadius: '5px',
                  padding: '10px',
                  backgroundColor: '#f9f9f9',
                  color: '#333',
                  fontSize: '16px',
                  width: '100%',
                  margin: '10px auto',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              />
                
              </div>
            <div>
              {fileUrl && <Button color="green" onClick={handleUpload} style={{ padding: "10px", width: "100%" }} block>Carga mi factura</Button>}
              {error && <div className="error">{error}</div>}
              {message && <div className="message">{message}</div>}
            </div>
          </Col>
        )}
      
        {showSpinner && (
          <div className="overlay" style={{ 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            width: '100%', 
            height: '100%', 
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            zIndex: 10
          }}>
          
        
          <div style={{ 
            backgroundColor: 'white', 
            border: '1px solid gray', 
            borderRadius: '10px', 
            padding: '20px', 
            textAlign: 'center' 
          }}>
            <CircularProgress color='black'/>
            <p style={{ color: 'black', paddingTop: 12, marginBottom: 0}}>{textSpinner}</p>
          </div>
   

          </div>
        )}

        { showInputs && 
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="grid-container">
              {Object.keys(data).map((key) => (
                <div key={key} className="grid-item">
                <Label for={key} style={{ opacity: isEditable ? 1 : 0, transition: 'opacity 0.5s' }}>{key}</Label>
                <Input
                  type="text"
                  name={key}
                  id={key}
                  value={data[key]}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  style={{ opacity: isEditable ? 1 : 0, transition: 'opacity 0.5s' }}
                />
                </div>
              ))}
              <div>
                {isEditable && <Button color="primary" onClick={handleSave} style={{ align:"center"}}>Guardar Factura</Button>}
              </div>
            </div>
          </Col>
        }


      {fileUrl && <Col lg={6} md={6} sm={12} xs={12} className="pdf-preview flex-column align-items-center justify-content-center " >
          
            <div className="d-flex justify-content-center align-items-center mb-3">
              <button
                type="button"
                disabled={pageNumber <= 1}
                onClick={goPrevPage}
              >
                Anterior
              </button>
              <span> Page {pageNumber} of {numPages}</span>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={goNextPage}
              >
                Siguiente
              </button>
            </div>
          

        <Document
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          noData={<div style={{display: fileUrl ? 'none' : 'block'}}> </div>}
        >
          <Page pageNumber={pageNumber}  renderAnnotationLayer={false} renderTextLayer={false} />
        </Document>
      </Col> }
    </Row>
    </div>
  );
};

export default PdfUploader;
