
import React from "react";
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import PdfUploader from "components/watiobot/invoiceProcessor.js";
import "assets/css/screens/invoice-processor-page.css"


function UploadInvoice() {

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs="12" md="12">
            <Card>
              <CardBody className="pdf-card-body">
                <PdfUploader />
              </CardBody>
            </Card>
          </Col>
          
        </Row>
      </div>
    </>
  );
}

export default UploadInvoice;
