import React from 'react';

export function getOrCreateTooltip(chart) {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'white';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'black';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, -50%)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
}

export function abbreviateText(inputText) {
  if(inputText.length > 17){
    return `${inputText.slice(0, 17)}...`;
  } else {
    return inputText;
  }  
}

export const externalTooltipHandler = (context, datasetVisibility) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    hideTooltip(tooltipEl);
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);
    const dataIndex = tooltip.dataPoints[0].dataIndex;

    const { barsData, keysWithMore } = extractBarsDataAndKeys(tooltip, dataIndex, datasetVisibility);

    const tableHead = createTableHead(titleLines, barsData, tooltip, datasetVisibility);
    const tableBody = createTableBody(barsData, keysWithMore, tooltip, datasetVisibility);

    updateTooltipContent(tooltipEl, tableHead, tableBody);
  }

  positionTooltip(chart, tooltipEl, tooltip);
};

const hideTooltip = (tooltipEl) => {
  tooltipEl.style.opacity = 0;
};

const extractBarsDataAndKeys = (tooltip, dataIndex, datasetVisibility) => {
  const bar0 = tooltip.dataPoints[0].dataset.data0[dataIndex];
  const bar1 = tooltip.dataPoints[0].dataset.data1[dataIndex];
  const bar2 = tooltip.dataPoints[0].dataset.data2[dataIndex];
  const arrayBar3 = tooltip.dataPoints[0].dataset.data3;

  let more;
  if (arrayBar3.length !== 0) {
    const moreArray = arrayBar3[0].map((_, colIndex) =>
      arrayBar3.reduce((sum, row) => sum + row[colIndex], 0)
    );
    more = moreArray[dataIndex];
  }

  let barsData = [bar0, bar1, bar2].filter(bar => bar !== undefined);
  const keys = tooltip.dataPoints[0].dataset.keys;

  if (keys.length > 3 && more !== undefined) {
    barsData.push(more);
  }

  let keysWithMore = keys.slice(0, 3);
  if (keys.length > 3) {
    keysWithMore.push("Otros");
  }

  return { barsData, keysWithMore };
};

const createTableHead = (titleLines, barsData, tooltip, datasetVisibility) => {
  const tableHead = document.createElement('thead');
  titleLines.forEach(title => {
    const tr = document.createElement('tr');
    tr.style.borderWidth = 0;

    const th = document.createElement('th');
    th.style.borderWidth = 0;

    const titleText = document.createTextNode(title);
    th.appendChild(titleText);

    const br = document.createElement('br');
    th.appendChild(br);

    const subtitleSpan = document.createElement('span');
    subtitleSpan.style.fontWeight = 'bold';

    const filteredData = barsData.filter((_, index) => !datasetVisibility.includes(index));
    let consumptionUnit = tooltip.dataPoints[0].dataset.unit;
    let kwSum = filteredData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    if (consumptionUnit === "MWh" && kwSum !== 0 && kwSum < 1) {
      consumptionUnit = "kWh";
      kwSum *= 1000;
    }

    const formattedValue = formatValue(kwSum);

    const subtitleText = document.createTextNode(`Consumo ${formattedValue} ${consumptionUnit}`);
    subtitleSpan.appendChild(subtitleText);

    th.appendChild(subtitleSpan);
    th.style.fontWeight = 'normal';
    tr.appendChild(th);
    tableHead.appendChild(tr);
  });

  return tableHead;
};

const createTableBody = (barsData, keysWithMore, tooltip, datasetVisibility) => {
  const tableBody = document.createElement('tbody');
  const filteredBarsData = barsData.filter((_, index) => !datasetVisibility.includes(index));
  const filteredKeysWithMore = keysWithMore.filter((_, index) => !datasetVisibility.includes(index));
  const filteredColors = tooltip.dataPoints[0].dataset.colors.filter((_, index) => !datasetVisibility.includes(index));

  filteredBarsData.forEach((data, i) => {
    const bgColor = filteredColors[i];
    const tr = document.createElement('tr');
    tr.style.backgroundColor = 'inherit';
    tr.style.borderWidth = 0;

    const td = document.createElement('td');
    td.style.borderWidth = 0;

    const span = createColorSpan(bgColor);
    const type = createStrongElement(filteredKeysWithMore[i]);
    const kWValue = createKwValueElement(data, tooltip.dataPoints[0].dataset.unit);

    td.appendChild(span);
    td.appendChild(type);
    td.appendChild(kWValue);
    tr.appendChild(td);
    tableBody.appendChild(tr);
  });

  return tableBody;
};

const createColorSpan = (bgColor) => {
  const span = document.createElement('span');
  span.style.background = bgColor;
  span.style.borderColor = "rgba(0,0,0,0.1)";
  span.style.borderWidth = '2px';
  span.style.marginRight = '10px';
  span.style.height = '10px';
  span.style.width = '10px';
  span.style.display = 'inline-block';
  span.style.borderRadius = '50%';

  return span;
};

const createStrongElement = (text) => {
  const strong = document.createElement('strong');
  strong.textContent = abbreviateText(text);

  return strong;
};

const createKwValueElement = (data, unit) => {
  if (unit === "MWh" && data !== 0 && data < 1) {
    data *= 1000;
    unit = "kWh";
  }

  const kwSumRounded = Math.round(data);
  const formattedValue = formatValue(kwSumRounded);

  const kWValue = document.createElement('span');
  kWValue.textContent = `${formattedValue} ${unit}`;
  kWValue.style.color = 'gray';
  kWValue.style.marginLeft = '10px';

  return kWValue;
};

const formatValue = (value) => {
  return new Intl.NumberFormat('de-DE').format(Math.round(value));
};

const updateTooltipContent = (tooltipEl, tableHead, tableBody) => {
  let tableRoot = tooltipEl.querySelector('table');

  if (!tableRoot) {
    tableRoot = document.createElement('table');
    tableRoot.style.margin = '0px';
    tooltipEl.appendChild(tableRoot);
  }

  while (tableRoot.firstChild) {
    tableRoot.firstChild.remove();
  }

  tableRoot.appendChild(tableHead);
  tableRoot.appendChild(tableBody);
};

const positionTooltip = (chart, tooltipEl, tooltip) => {
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  const chartRect = chart.canvas.getBoundingClientRect();
  const tooltipRect = tooltipEl.getBoundingClientRect();
  const windowHeight = window.innerHeight;

  let newX = positionX + chart.tooltip.caretX;
  let newY = positionY + chart.tooltip.caretY;

  if (chartRect.x - chart.tooltip.caretX < 75) {
    newX = chart.tooltip.caretX - 75;
  }
  if (newY + tooltipRect.height > windowHeight) {
    newY = windowHeight - tooltipRect.height;
  }
  if (newY < 0) {
    newY = 0;
  }

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = `${newX}px`;
  tooltipEl.style.top = `${newY}px`;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
  tooltipEl.style.border = '1px solid gray';
  tooltipEl.style.minWidth = '300px';
};