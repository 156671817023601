import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import ex1json from "assets/mockdata/ex1.json"
import ex2json from "assets/mockdata/ex2.json"
import ex3json from "assets/mockdata/ex3.json"
import ex4json from "assets/mockdata/ex4.json"
import { externalTooltipHandler } from './CustomTooltip';
import 'assets/css/components/custom-chart.css'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Select from 'react-select'
import { useAuth } from 'providers/authProvider2';
import { useNavigate } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";

const apiEndpoint=process.env.REACT_APP_AI_CORE_API_ENDPOINT||'https://api.watiobot.ai';
const api = axios.create({
  baseURL: apiEndpoint
});

const ChartComponent = () => {

  const { clearTokenFromSessionStorage } = useAuth();
  const [accessToken] = useState(() => sessionStorage.getItem('access_token'));
  const navigate = useNavigate();

  const [originalDataSet, setOriginalDataSet] = useState();
  const [dataSet, setDataSet] = useState();

  const [originalData, setOriginalData] = useState();
  const [data, setData] = useState({
    labels: ['January', 'February', 'March', 'April'],
    datasets: [{
      label: 'Consumption',
      data: [],
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(75,192,192,0.4)',
      hoverBorderColor: 'rgba(75,192,192,1)',
    }],
  });
  const [isLoading, setIsLoading] = useState(false);

  // Use default start and end dates if none are provided
  const now = new Date();
  const year = now.getFullYear() - 1; // Go to last year
  const defaultStartDate = `${year}-01-01`; // Start of the last year
  const defaultEndDate = `${year}-12-31`; // End of the last year

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });

  // consumption unit
  const [consumptionUnit, setConsumptionUnit] = useState();

  // filters
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [selectedInstallations, setSelectedInstallations] = useState([]);
  const [selectedCups, setSelectedCups] = useState([]);

  const [contractOptions, setContractOptions] = useState([]);
  const [installationsOptions, setInstallationsOptions] = useState([]);
  const [cupsOptions, setCupsOptions] = useState([]);

  const [filterType, setFilterType] = useState("cups");
  const [initialData, setInitialData] = useState();

  const [showFilterError, setShowFilterError] = useState(false);

  // date picker
  const [shortCutSelected, setShortCutSelected] = useState([]);
  const shortCutOptions = [
    { value: 'Dia', label: 'Dia' },
    { value: '3 días', label: '3 días' },
    { value: 'Semana', label: 'Semana' },
    { value: 'Mes', label: 'Mes' },
    { value: 'Trimestre', label: 'Trimestre' },
    { value: 'Año', label: 'Año' }
  ]

  // data from typebot
  const [dataTypebot, setDataTypebot] = useState({
    startDate: "",
    endDate: "",
    type: ""
  });

  // tooltip
  const [datasetTooltipVisibility, setDatasetTooltipVisibility] = useState([]);


  useEffect(() => {
    const handleMessage = (event) => {

     /* if (event.origin !== 'http://localhost:3000') {
        return;
      }*/
      if (event.data.type === 'UPDATE_GRAPH') {
        updateGraph(event.data.payload);
        setDataTypebot({
          startDate: event.data.payload.startDate,
          endDate: event.data.payload.endDate,
          type: event.data.payload.type});
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const updateGraph = (payload) => {
    console.log('Function called from typebot with payload:', payload);
    fetchData(payload.startDate, payload.endDate)
  };

  const getFilterOptions = (data) => {
    const dataSet = data.dataSet;

    // Extract unique Contracts
    const uniqueContracts = new Set();
    Object.keys(dataSet).forEach((key) => {
        uniqueContracts.add(dataSet[key].contract);
    });

    const contractsOptions = Array.from(uniqueContracts).map((contract) => ({
        value: contract,
        label: contract,
    }));

    setContractOptions(contractsOptions);
    setSelectedContracts(contractsOptions);

    // Extract unique installations
    const uniqueInstallations = new Set();
    Object.keys(dataSet).forEach((key) => {
        uniqueInstallations.add(dataSet[key].installation);
    });

    const installationsOptions = Array.from(uniqueInstallations).map((installation) => ({
        value: installation,
        label: installation,
    }));

    setInstallationsOptions(installationsOptions);
    setSelectedInstallations(installationsOptions);

    // Extract unique cups
    const uniqueCups = new Set(Object.keys(dataSet));

    const cupsOptions = Array.from(uniqueCups).map((cup) => ({
        value: cup,
        label: cup,
    }));

    setCupsOptions(cupsOptions);
    setSelectedCups(cupsOptions);

  }

  const transformData = (data, energyUnit) => {

    const calculateBar = (key, labels, dataSet) => {
      return labels.map(label => calculateLabelSum(dataSet[key].data[label]));
    };
  
    const calculateBars = (keys, labels, dataSet) => {
      return keys.map(key => calculateBar(key, labels, dataSet));
    };
  
    const calculateLabelSum = (data) => {
      return data ? sumValues(data) : 0;
    };
  
    const sumValues = (data) => {
      return Object.values(data).reduce((sum, value) => sum + value, 0);
    };

    const calculateValues = (key, labels, dataSet) => {
      return labels.map(label => calculateLabelSum(dataSet[key].data[label]));
    };
    
    const calculateMoreData = (keys, labels, dataSet) => {
      const moreData = new Array(labels.length).fill(0);
      keys.forEach(key => {
        labels.forEach((label, idx) => {
          moreData[idx] += calculateLabelSum(dataSet[key].data[label]);
        });
      });
      return moreData;
    };
    
    const labelsSet = new Set();
    Object.values(data.dataSet).forEach(item => {
      Object.keys(item.data).forEach(label => labelsSet.add(label));
    });
    
    const labels = Array.from(labelsSet);
  
    const dataSet = data.dataSet;
  
    const backgroundColors = [
      'rgb(192, 228, 254)',
      'rgb(133, 177, 250)',
      'rgb(251, 180, 126)',
      'rgb(100, 180, 126)'
    ];
  
    const keys = Object.keys(dataSet);
    const datasets = [];
  
    const bar0 = keys.length > 0 ? calculateBar(keys[0], labels, dataSet) : [];
    const bar1 = keys.length > 1 ? calculateBar(keys[1], labels, dataSet) : [];
    const bar2 = keys.length > 2 ? calculateBar(keys[2], labels, dataSet) : [];
    const bar3 = keys.length > 3 ? calculateBars(keys.slice(3), labels, dataSet) : [];

    keys.slice(0, 3).forEach((key, index) => {
      const values = calculateValues(key, labels, dataSet);
      datasets.push({
        type: 'bar',
        label: key,
        data: values,
        data0: bar0,
        data1: bar1,
        data2: bar2,
        data3: bar3,
        colors: backgroundColors,
        keys: keys,
        backgroundColor: backgroundColors[index % backgroundColors.length],
        stack: 'stack1',
        order: 2,
        unit: energyUnit
      });
    });
    
    if (keys.length > 3) {
      const moreData = calculateMoreData(keys.slice(3), labels, dataSet);
      datasets.push({
        type: 'bar',
        label: 'Otros',
        data: moreData,
        data0: bar0,
        data1: bar1,
        data2: bar2,
        data3: bar3,
        colors: backgroundColors,
        keys: keys,
        backgroundColor: 'rgb(100, 180, 126)',
        stack: 'stack1',
        order: 2,
        unit: energyUnit
      });
    }
  
    return { labels, datasets };
  };

    function hasValuesWithMoreThanSixDigits(dataSet) {
        for (const details of Object.values(dataSet)) {
            for (const data of Object.values(details.data)) {
                for (const value of Object.values(data)) {
                    // Check if the value is a number and has more than six digits
                    if (typeof value === 'number' && value >= 1000000) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    function convertValuesToMWh(dataSet) {
        const convertedDataSet = {};

        for (const [cup, details] of Object.entries(dataSet)) {
            const updatedDetails = { ...details };

            for (const [date, data] of Object.entries(details.data)) {
                for (const [key, value] of Object.entries(data)) {
                    // Check if the value is a number, then convert
                    if (typeof value === 'number') {
                        updatedDetails.data[date][key] = value / 1000;
                    }
                }
            }
            convertedDataSet[cup] = updatedDetails;
        }
        return convertedDataSet;
    }   


  const fetchData = async (start, end) => {
    setIsLoading(true);
    try {
      console.log("Sending request for getting consumptions with params:",start, end);
      const response = await api.post('/get-consumptions-by-cups', {
        "startDate": start,
        "endDate": end
      },{
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      } );
      let apiData = response.data.payload;
      let formattedData;
       
      // convert kWh to MWh if more than 6 digits
      if(hasValuesWithMoreThanSixDigits(apiData.dataSet)){
        apiData.dataSet = convertValuesToMWh(apiData.dataSet);
        setConsumptionUnit("MWh");
        formattedData = transformData(apiData, "MWh");
      } else {
        setConsumptionUnit("kWh");
        formattedData = transformData(apiData, "kWh");
      }

      //save original data
      setOriginalDataSet(apiData.dataSet);
      setOriginalData(formattedData);

      //set filter type
      setFilterType(apiData.type);
      getFilterOptions(apiData);

      setData(formattedData);
      setDataSet(apiData.dataSet);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if(error.response != null && error.response.status == 401){
        clearTokenFromSessionStorage();
        navigate("/auth/login-page");
      }
    } finally {
    setIsLoading(false);
  }
  };

  useEffect(() => {

    fetchData(startDate, endDate);
    setInitialData({"startDate": startDate, "endDate": endDate, "type": filterType});
    
    setDataTypebot({
      startDate: "",
      endDate: "",
      type: ""
    });
  }, []); // Array de dependencias vacío significa que este efecto se ejecuta una vez después del primer renderizado

  const handleStartDateChange = (event) => {
    setFilterType("date");
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
  };
  
  const handleEndDateChange = (event) => {
    setFilterType("date");
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
  };

  const handleLegendClick = (e, legendItem, legend) => {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;
    const meta = ci.getDatasetMeta(index);

    let hiddenIndex = datasetTooltipVisibility;

    if(meta.hidden === null){
      hiddenIndex.push(meta.index);
    } else {
      if(hiddenIndex.includes(meta.index)){
        hiddenIndex = hiddenIndex.filter(val => val !== meta.index);
      }
      
    }
    setDatasetTooltipVisibility(hiddenIndex);
    meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

    // Redraw the chart
    ci.update();
  };
  
  const optionsV2 = {
    plugins: {
      title: {
        display: true,
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        padding: 12,
        external: (context) => externalTooltipHandler(context, datasetTooltipVisibility),
        callbacks: {
          label: function (context) {
            let data = context.dataset.data
            let data1 = context.dataset.data1
            return context.formattedValue + " kw"
          },
          beforeLabel: function(data) {
            return "beforeLabel";
        },
        },
      },
      legend: {
        onClick: handleLegendClick,
      },
    },
    
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
        callback: function(value) {
          const formattedValue = new Intl.NumberFormat('de-DE').format(value);
          return formattedValue + ' ' + consumptionUnit;
        }
      }
      },
    },
  };

  // filters
  function sortOptionsBySelected(options, selectedItems) {
    return [...options].sort((a, b) => {
        const aSelected = selectedItems.some((selected) => selected.value === a.value);
        const bSelected = selectedItems.some((selected) => selected.value === b.value);
        if (aSelected && !bSelected) return -1;
        if (!aSelected && bSelected) return 1;
        return 0;
    });
}

// Logic for Contracts Filter 
  function contractsOnChange(value, event) {
    let newSelectedContracts = []

    if (event.action === "select-option") {
      newSelectedContracts = value;
  } else if (event.action === "deselect-option") {
      if (value.length > 0) {
          newSelectedContracts = selectedContracts.filter(cont =>
              value.some(deselected => deselected.value === cont.value)
          );
      } else {
        newSelectedContracts = [];
      }
  }

    setFilterType("contract");
    setSelectedContracts(newSelectedContracts);
    updateInstallationsAndCups(newSelectedContracts);

    //hide filter empty error
    setShowFilterError(false);
  }

  function updateInstallationsAndCups(selectedContracts){

    let newSelectedInstallations = [];
    let newSelectedCups = [];

    selectedContracts.forEach(contract => {
        // Get all installations and CUPS related to each selected contract
        Object.keys(originalDataSet).forEach(cupKey => {
            const cupDetails = originalDataSet[cupKey];

            if (cupDetails.contract === contract.value) {
                newSelectedInstallations.push({ value: cupDetails.installation, label: cupDetails.installation });
                newSelectedCups.push({ value: cupKey, label: cupKey });
            }
        });
    });

    // Remove duplicates from installations and CUPS
    newSelectedInstallations = Array.from(new Set(newSelectedInstallations.map(a => JSON.stringify(a))))
                                     .map(item => JSON.parse(item));

    newSelectedCups = Array.from(new Set(newSelectedCups.map(a => JSON.stringify(a))))
                           .map(item => JSON.parse(item));

    // Update state with the installations and CUPS related to the selected contracts
    setSelectedInstallations(newSelectedInstallations);
    setSelectedCups(newSelectedCups);

    // Sort contract options
    const sortedContractOptions = sortOptionsBySelected(contractOptions, selectedContracts);
    setContractOptions(sortedContractOptions);

    // Sort installations options
    const sortedInstallationsOptions = sortOptionsBySelected(installationsOptions, newSelectedInstallations);
    setInstallationsOptions(sortedInstallationsOptions);

     // Sort cups options
    const sortedCupsOptions = sortOptionsBySelected(cupsOptions, newSelectedCups);
    setCupsOptions(sortedCupsOptions);
  }

  // Logic for Installations Filter 
  function installationsOnChange(value, event) {
    let newSelectedInstallations = [];

    if (event.action === "select-option") {
        newSelectedInstallations = value; // Update installations
    } else if (event.action === "deselect-option") {
        if (value.length > 0) {
            // Filter out deselected installations from selectedInstallations
            newSelectedInstallations = selectedInstallations.filter((install) => {
                return value.some((deselected) => deselected.value === install.value);
            });
        } else {
            newSelectedInstallations = [];
        }
    }
    setFilterType("installation");
    setSelectedInstallations(newSelectedInstallations);

    // Update Contracts and CUPs based on selected installations
    updateContractsAndCups(newSelectedInstallations);

    //hide filter empty error
    setShowFilterError(false);
  }

  function groupInstallationsByContract(originalDataSet) {
    const contractInstallationsMap = {};
    Object.keys(originalDataSet).forEach((cup) => {
      const installation = originalDataSet[cup].installation;
      const contract = originalDataSet[cup].contract;

      if (!contractInstallationsMap[contract]) {
        contractInstallationsMap[contract] = new Set();
      }
      contractInstallationsMap[contract].add(installation);
    });
    return contractInstallationsMap;
  }

  function collectCupsByInstallation(installation, originalDataSet) {
    return Object.keys(originalDataSet)
      .filter(cup => originalDataSet[cup].installation === installation)
      .map(cup => ({ value: cup, label: cup }));
  }


  function updateFullySelectedContracts(contract, installations, selectedInstallations, originalDataSet) {
    const fullySelectedContracts = [];
    const selectedCups = [];

    const allInstallationsSelected = installations.every(installation =>
      selectedInstallations.some(sel => sel.value === installation)
    );

    if (allInstallationsSelected) {
      fullySelectedContracts.push(contract);
      installations.forEach(installation => {
        selectedCups.push(...collectCupsByInstallation(installation, originalDataSet));
      });
    }
    return { fullySelectedContracts, selectedCups };
  }

  function updatePartiallySelectedCups(installations, selectedInstallations, originalDataSet) {
    const partiallySelectedCups = [];
    installations.forEach(installation => {
      if (selectedInstallations.some(sel => sel.value === installation)) {
        partiallySelectedCups.push(...collectCupsByInstallation(installation, originalDataSet));
      }
    });
    return partiallySelectedCups;
  }


  function updateContractsAndCups(selectedInstallations) {
    const contractInstallationsMap = groupInstallationsByContract(originalDataSet);
    const fullySelectedContracts = [];
    const selectedCupsSet = new Set();
    const partiallySelectedCupsSet = new Set();

    Object.keys(contractInstallationsMap).forEach(contract => {
      const installations = Array.from(contractInstallationsMap[contract]);
      const { fullySelectedContracts: fullyContracts, selectedCups: cups } = updateFullySelectedContracts(
        contract, installations, selectedInstallations, originalDataSet
      );

      fullySelectedContracts.push(...fullyContracts);
      cups.forEach(cup => selectedCupsSet.add(cup.value));
      updatePartiallySelectedCups(installations, selectedInstallations, originalDataSet)
        .forEach(cup => partiallySelectedCupsSet.add(cup.value));
    });

    const allInstallationsCovered = selectedInstallations.every(install =>
      fullySelectedContracts.some(contract =>
        contractInstallationsMap[contract].has(install.value)
      )
    );

    if (allInstallationsCovered) {
      const fullyContracts = fullySelectedContracts.map(contract => ({ value: contract, label: contract }));
      setSelectedContracts(fullyContracts);

      // Sort contract options
      const sortedContractOptions = sortOptionsBySelected(contractOptions, fullyContracts);
      setContractOptions(sortedContractOptions);
    } else {
      setSelectedContracts([]);
    }

    // Sort installations options
    const sortedInstallationsOptions = sortOptionsBySelected(installationsOptions, selectedInstallations);
    setInstallationsOptions(sortedInstallationsOptions);

    // Convert Sets back to arrays and create unique CUPs list
    const uniqueSelectedCups = Array.from(new Set([...selectedCupsSet, ...partiallySelectedCupsSet])).map(value => ({
      value,
      label: value
    }));

    // Update CUPs regardless of whether contracts are fully selected
    setSelectedCups(uniqueSelectedCups);

    // Sort cups options
    const sortedCupsOptions = sortOptionsBySelected(cupsOptions, uniqueSelectedCups);
    setCupsOptions(sortedCupsOptions);
  }

// Logic for CUPs Filter 
function cupsOnChange(value, event) {
  let newSelectedCups = [];

  if (event.action === "select-option") {
      newSelectedCups = value;
  } else if (event.action === "deselect-option") {
      if (value.length > 0) {
          // Filter out deselected cups from selectedCups
          newSelectedCups = selectedCups.filter(cup =>
              value.some(deselected => deselected.value === cup.value)
          );
      } else {
          newSelectedCups = [];
      }
  }
  setFilterType("cups");
  setSelectedCups(newSelectedCups);
  updateInstallationsAndContracts(newSelectedCups);

  //hide filter empty error
  setShowFilterError(false);
}

function updateInstallationsAndContracts(selectedCups) {
   
  let selectedInstallations = [];
  let selectedContracts = [];
  let fullySelectedInstallations = [];
  let partiallySelectedInstallations = [];
  let fullySelectedContracts = [];
  let partiallySelectedContracts = [];

  const installationCupsMap = {};
  const contractInstallationsMap = {};

  // Populate the installationCupsMap
  Object.keys(originalDataSet).forEach(cupKey => {
      const cupDetails = originalDataSet[cupKey];
      const installation = cupDetails.installation;
      const contract = cupDetails.contract;

      if (!installationCupsMap[installation]) {
          installationCupsMap[installation] = {
              allCups: new Set(),
              selectedCups: new Set(),
          };
      }

      if (!contractInstallationsMap[contract]) {
          contractInstallationsMap[contract] = new Set();
      }

      installationCupsMap[installation].allCups.add(cupKey);
      contractInstallationsMap[contract].add(installation);
  });

  // Track selected CUPS and related installations and contracts
  selectedCups.forEach(cup => {
      const cupDetails = originalDataSet[cup.value];
      const installation = cupDetails.installation;

      if (installationCupsMap[installation]) {
          installationCupsMap[installation].selectedCups.add(cup.value);
      }

      selectedInstallations.push({ value: cupDetails.installation, label: cupDetails.installation });
      selectedContracts.push({ value: cupDetails.contract, label: cupDetails.contract });
  });

  // No duplicated
  selectedInstallations = Array.from(new Set(selectedInstallations.map(a => JSON.stringify(a))))
                               .map(item => JSON.parse(item));

  selectedContracts = Array.from(new Set(selectedContracts.map(a => JSON.stringify(a))))
                            .map(item => JSON.parse(item));

  // Determine fully and partially selected installations
  Object.keys(installationCupsMap).forEach(installation => {
      const { allCups, selectedCups } = installationCupsMap[installation];

      if (allCups.size === selectedCups.size) {
          fullySelectedInstallations.push({ value: installation, label: installation });
      } else if (selectedCups.size > 0) {
          partiallySelectedInstallations.push({ value: installation, label: installation });
      }
  });

  // Determine fully and partially selected contracts
  Object.keys(contractInstallationsMap).forEach(contract => {
      const allInstallations = Array.from(contractInstallationsMap[contract]);
      const selectedInstallationsSet = new Set(fullySelectedInstallations.map(installation => installation.value));

      const allSelected = allInstallations.every(installation => selectedInstallationsSet.has(installation));
      const someSelected = allInstallations.some(installation => selectedInstallationsSet.has(installation));

      if (allSelected) {
          fullySelectedContracts.push({ value: contract, label: contract });
      } else if (someSelected) {
          partiallySelectedContracts.push({ value: contract, label: contract });
      }
  });

    // Conditionally update state based on partially selected items
    if (partiallySelectedInstallations.length == 0) {
      setSelectedInstallations(fullySelectedInstallations);

      // Sort installations options
      const sortedInstallationsOptions = sortOptionsBySelected(installationsOptions, fullySelectedInstallations);
      setInstallationsOptions(sortedInstallationsOptions);
    } else {
      setSelectedInstallations([]);
    }

    if (partiallySelectedInstallations.length == 0 && partiallySelectedContracts.length == 0) {
      setSelectedContracts(fullySelectedContracts);

      // Sort contract options
      const sortedContractOptions = sortOptionsBySelected(contractOptions, fullySelectedContracts);
      setContractOptions(sortedContractOptions);
    } else {
      setSelectedContracts([]);
    }

    // Sort cups options
    const sortedCupsOptions = sortOptionsBySelected(cupsOptions, selectedCups);
    setCupsOptions(sortedCupsOptions);
}

// Logic for Apply Filter
function handleApplyFilter() {

  // Check if all filters are empty
  const noFiltersSelected = selectedCups.length === 0 && selectedInstallations.length === 0 && selectedContracts.length === 0;

  if (noFiltersSelected) {
      setShowFilterError(true);
      return;
  }

  // Clean the bars that were hidden by the legend
  setDatasetTooltipVisibility([]);

  if (filterType === "date") {
      fetchData(startDate, endDate);
      return;
  }

  // Determine selected items based on filter type
  let itemsSelected;
  switch (filterType) {
      case "cups":
          itemsSelected = selectedCups;
          break;
      case "installation":
          itemsSelected = selectedInstallations;
          break;
      case "contract":
          itemsSelected = selectedContracts;
          break;
      default:
          itemsSelected = [];
  }

  // Apply the selected filter and update the dataset
  const newDataSet = applyFilter(filterType, itemsSelected);
  const formattedData = transformData(newDataSet, consumptionUnit);

  setData(formattedData);
  setDataSet(newDataSet.dataSet);
}

  function cleanFilters() {
    //clean the bars that were hidden by legend
    setDatasetTooltipVisibility([]);

    setStartDate(initialData.startDate);
    setEndDate(initialData.endDate);
    fetchData(initialData.startDate, initialData.endDate);

    //hide filter empty error
    setShowFilterError(false);
  }

  function applyFilter(filterType, selectedItems) {
      const selectedValues = selectedItems.map(item => item.value);
      let newDataSet = { type: filterType, dataSet: {} };

      Object.keys(originalDataSet).forEach(cupKey => {
          const cupDetails = originalDataSet[cupKey];
          const installation = cupDetails.installation;
          const contract = cupDetails.contract;

          if (filterType === "cups" && selectedValues.includes(cupKey)) {
              newDataSet.dataSet[cupKey] = cupDetails;
          } else if (filterType === "installation" && selectedValues.includes(installation)) {
              addInstallationData(newDataSet, installation, contract, cupDetails);
          } else if (filterType === "contract" && selectedValues.includes(contract)) {
              addContractData(newDataSet, contract, installation, cupDetails);
          }
      });

      return newDataSet;
  }

  function addInstallationData(newDataSet, installation, contract, cupDetails) {
      if (!newDataSet.dataSet[installation]) {
          newDataSet.dataSet[installation] = {
              data: {},
              contract: contract,
              installation: installation
          };
      }
      mergeData(newDataSet.dataSet[installation].data, cupDetails.data);
  }

  function addContractData(newDataSet, contract, installation, cupDetails) {
      if (!newDataSet.dataSet[contract]) {
          newDataSet.dataSet[contract] = {
              data: {},
              contract: contract,
              installation: installation
          };
      }
      mergeData(newDataSet.dataSet[contract].data, cupDetails.data);
  }

  function mergeData(targetData, sourceData) {
    for (const [dateKey, sourceEntries] of Object.entries(sourceData)) {
        const targetEntries = targetData[dateKey] ||= {};
        for (const [dataKey, value] of Object.entries(sourceEntries)) {
            targetEntries[dataKey] = (targetEntries[dataKey] || 0) + value;
        }
    }
  }

//datepicker shortcuts
const setMonthShortcut = () => {
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  const formattedStartDate = firstDayOfMonth.toISOString().split('T')[0];
  const formattedEndDate = lastDayOfMonth.toISOString().split('T')[0];

  setStartDate(formattedStartDate);
  setEndDate(formattedEndDate);
};

const setWeekShortcut = () => {
  const now = new Date();
  const dayOfWeek = now.getDay(); 
  const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  const firstDayOfWeek = new Date(now);
  firstDayOfWeek.setDate(now.getDate() + diffToMonday);
  
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  const formattedStartDate = firstDayOfWeek.toISOString().split('T')[0];
  const formattedEndDate = lastDayOfWeek.toISOString().split('T')[0];

  setStartDate(formattedStartDate);
  setEndDate(formattedEndDate);
};

const setThreeDaysShortcut = () => {
  const now = new Date();

  const threeDaysLater = new Date(now);
  threeDaysLater.setDate(now.getDate() + 3);

  const formattedStartDate = now.toISOString().split('T')[0];
  const formattedEndDate = threeDaysLater.toISOString().split('T')[0];

  setStartDate(formattedStartDate);
  setEndDate(formattedEndDate);
};

const setQuarterShortcut = () => {
  const now = new Date();

  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayOfThirdMonth = new Date(now.getFullYear(), now.getMonth() + 3, 0);

  const formattedStartDate = firstDayOfMonth.toISOString().split('T')[0];
  const formattedEndDate = lastDayOfThirdMonth.toISOString().split('T')[0];

  setStartDate(formattedStartDate);
  setEndDate(formattedEndDate);
};

const setYearShortcut = () => {
  const now = new Date();

  const firstDayOfYear = new Date(now.getFullYear(), 0, 1);
  const lastDayOfYear = new Date(now.getFullYear(), 11, 31);

  const formattedStartDate = firstDayOfYear.toISOString().split('T')[0];
  const formattedEndDate = lastDayOfYear.toISOString().split('T')[0];

  setStartDate(formattedStartDate);
  setEndDate(formattedEndDate);
};

const setTodayShortcut = () => {
  const now = new Date();
  
  const formattedDate = now.toISOString().split('T')[0];

  setStartDate(formattedDate);
  setEndDate(formattedDate);
};

const shortcutActions = {
  "Dia": setTodayShortcut,
  "3 días": setThreeDaysShortcut,
  "Semana": setWeekShortcut,
  "Mes": setMonthShortcut,
  "Trimestre": setQuarterShortcut,
  "Año": setYearShortcut, 
};

const onChangeShortCut = (e) => {

  if(e != null){
    setFilterType("date");
    const action = shortcutActions[e.value];
    if (action) {
      action();
    }
  }

};

  if (isLoading) {
    return (
      <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ width: "100%", height: "10px", backgroundColor: "#ccc" }}>
          <div style={{ height: "10px", width: "50%", backgroundColor: "#4caf50", animation: "progress 2s ease-out infinite" }}></div>
        </div>
      </div>
    );
    }

  return (
    <div>
      <div className='filters'>
      <Row>
        <Col md="4" sm="4" xs="12">
          <label className={`mt-2 type-title ${filterType === "contract" ? "type-selected" : ""}`}>Contratos:</label> 
            <ReactMultiSelectCheckboxes 
              className="filter-contract"
              options={contractOptions} 
              onChange={contractsOnChange} 
              placeholderButtonLabel=" "
              value={selectedContracts}
              setState={setSelectedContracts}/>
          <label>{selectedContracts.length} de {contractOptions.length} contratos seleccionados</label> 
        </Col>

        <Col md="4" sm="4" xs="12">
          <label className={`mt-2 type-title ${filterType === "installation" ? "type-selected" : ""}`}>Instalaciones:</label> 
            <ReactMultiSelectCheckboxes 
              options={installationsOptions} 
              onChange={installationsOnChange} 
              placeholderButtonLabel=" " 
              value={selectedInstallations}
              setState={setSelectedInstallations}/>
          <label>{selectedInstallations.length} de {installationsOptions.length} instalaciones seleccionadas</label> 
        </Col>

        <Col md="4" sm="4" xs="12">
          <label className={`mt-2 type-title ${filterType === "cups" ? "type-selected" : ""}`}>CUPS:</label> 
          <ReactMultiSelectCheckboxes 
              options={cupsOptions} 
              onChange={cupsOnChange} 
              placeholderButtonLabel=" " 
              value={selectedCups}
              setState={setSelectedCups}/>
          <label>{selectedCups.length}  de {cupsOptions.length} cups seleccionados</label> 
        </Col>

      </Row>
      {showFilterError && <p style={{ color: 'red', textAlign: 'center', marginBottom: 0}}>No hay datos seleccionados</p>}
      <Row className={`type-date align-items-end ${!showFilterError ? 'mt-3' : ''}`}>
        <Col md="auto" sm="auto" xs="auto" className='mb-1'>
        <label className="type-title">Fecha de Inicio - Fin</label>
          <Row className='ml-1'>
            <Select 
            placeholder={"Seleccionar"}
            options={shortCutOptions} 
            onChange={onChangeShortCut} 
            isClearable={true}/>

            <input className='mx-2' type="date" value={startDate} onChange={handleStartDateChange} />
            <input className='mx-0' type="date" value={endDate} onChange={handleEndDateChange} />
          </Row>
        </Col>
        <Col md="auto" sm="auto" xs="auto">
          <div className='row-btns mr-1'>
            <Button
              className='apply-btn mx-1'
              onClick={handleApplyFilter}>
                Aplicar filtros
            </Button>

            <Button
              className='clean-btn'
              onClick={cleanFilters}>
                <i className="now-ui-icons ui-1_simple-remove"/>
            </Button>
          </div>
        </Col>
      </Row>

    </div>

    <div>
      {consumptionUnit && <Bar data={data} options={optionsV2} />}
    </div>
    
    
    </div>
  );
};

export default ChartComponent;