
export const isUsernameValid = (username) => {
  const trimmedUsername = username.trim();
  const regex = /^[a-zA-Z0-9_-]+$/;
  return regex.test(trimmedUsername);
};


  export const isPasswordValid = (password) => {
    const trimmedPassword = password.trim();
    const minLength = 6;
    const maxLength = 20;
    //const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'*+,-\.<=>?@\[\]^_`{|}~])[^\s]{minLength,maxLength}$/;
  
    const errors = [];
  
    if (!trimmedPassword) {
      errors.push('Password is required.');
    } else if (trimmedPassword.length < minLength) {
      errors.push(`Password must be at least ${minLength} characters long.`);
    } else if (trimmedPassword.length > maxLength) {
      errors.push(`Password must be less than ${maxLength + 1} characters long.`);
    } 
  
    return errors.length === 0;
  };

  export const isEmailValid = (email) => {
    const trimmedEmail = email.trim();
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(trimmedEmail);
  };