
import React from "react";


// reactstrap components
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";


import TypebotChat from "components/watiobot/typebot.js";


import ChartComponent from "components/watiobot/ChartComponent.js";


function Pilot() {

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs="12" md="4">
            <Card>
              <CardHeader><h2>Habla con tus facturas</h2></CardHeader>
              
              <CardBody className="botCard" style={{   height: "700px",  overscrollBehavior: "contain"}}>

                <TypebotChat />
              </CardBody>
            </Card>

            
          </Col>
          <Col xs="12" md="8">
            <Card>
              <CardHeader><h2>Tu resumen de datos</h2></CardHeader>
              
              <CardBody className="botCard" style={{   height: "700px",  overscrollBehavior: "contain"}}>

                <ChartComponent />
              </CardBody>
            </Card>

            
          </Col>
          
        </Row>
      </div>
    </>
  );
}

export default Pilot;
