/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.2
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { isPasswordValid, isUsernameValid, isEmailValid } from '../../utils/validations.js';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Spinner
} from "reactstrap";

// core components
import nowLogo from "assets/img/miniwatiobot.png";
import bgImage from "assets/img/bg14.jpg";
import zIndex from "@material-ui/core/styles/zIndex.js";
import "assets/css/screens/login-page.css";
import { getToken, getUserInfo, forgotPassword } from "utils/authApiManager.js";
import { useAuth } from "providers/authProvider2.js";


function LoginPage() {
  const [usernameFocus, setUsernameFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { saveTokenToSessionStorage, saveUserInfo, accessToken } = useAuth();
  const [loading, setLoading] = useState(false);

  const [showForgotDialog, setShowForgotDialog] = useState(false);
  const [userEmail, setUserEmail] = React.useState('');
  const [showForgotPassError, setShowForgotPassError] = useState(false);
  const [forgotPassErrorMsg, setForgotPassErrorMsg] = useState("");
  const [forgotPassRequestSuccess, setForgotPassRequestSuccess] = useState(false);

 // checkTokenValidity 
 const checkTokenValidity = async () => {
  if (accessToken) {
    try {
      const userInfo = await getUserInfo(accessToken);
      await saveUserInfo(userInfo);
      navigate("/user/dashboard-page");
    } catch (error) {

    }
  }
};

useEffect(() => {
  checkTokenValidity();
}, []);


  const keycloakLogin = async () => {
    try {
      setShowError(false);
      setLoading(true);
      const tokenData = await getToken(username, password, saveTokenToSessionStorage);
      const userInfo = await getUserInfo(tokenData.access_token);
      await saveUserInfo(userInfo);
      navigate("/user/dashboard-page");
    } catch (error) {
      if (error.error_description) {
        setErrorMsg(error.error_description);
        setShowError(true);
      } else {
        setErrorMsg("Ha ocurrido un error");
        setShowError(true);
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterClick = (e) => {
    e.preventDefault();
    navigate("/auth/register-page");
  };

  const showForgotPassDialog = (e) => {
    e.preventDefault();
    setShowForgotDialog(true)
  };

  const closeDialog = (e) => {
    e.preventDefault();
    setShowForgotDialog(false)
  };

  const handleForgotPassClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await forgotPassword(userEmail);
      setForgotPassRequestSuccess(true);
      setTimeout(() => {
        setShowForgotDialog(false);
        setForgotPassRequestSuccess(false);
      }, 2000);
    } catch (error) {
      setShowForgotPassError(true);
      setForgotPassErrorMsg(error);
    } finally {
      setLoading(false);
    }
  };

  function getForgotButtonStatus() {
    return isEmailValid(userEmail);
  }

  function getButtonStatus() {
    const user = isUsernameValid(username);
    const pass = isPasswordValid(password);
    return user && pass;
  }

  useEffect(() => {
    setShowError(false);
    setErrorMsg("");
    setShowForgotPassError(false);
    setForgotPassErrorMsg("");
    document.body.classList.add("login-page");
    return () => {
      document.body.classList.remove("login-page");
    };
  }, []);

  return (
    <>
          <div className="content">

          {showForgotDialog && (
              <div className="overlay" >
                <Card className="forgot-card">
                  <a className="close-btn" onClick={closeDialog}><i className="now-ui-icons ui-1_simple-remove" /></a>
                   {!forgotPassRequestSuccess ? <>
                      <CardHeader>
                        <div className="card-title" style={{fontWeight: 'bold'}}>¿Olvidaste la contraseña?</div>
                      </CardHeader>
                      <CardBody>
                      <div className="card-subtitle"> Ingresa tu email, para poder recuperar tu contraseña.</div>
                          <InputGroup
                            className={emailFocus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons ui-1_email-85" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              color="green"
                              type="email"
                              placeholder=""
                              onFocus={(e) => setEmailFocus(true)}
                              onBlur={(e) => setEmailFocus(false)}
                              onChange={(e) => setUserEmail(e.target.value)}
                            />
                          </InputGroup>
                      </CardBody>
                        <CardFooter>
                            {showForgotPassError && <p style={{ color: 'red', textAlign: 'center' }}>{forgotPassErrorMsg}</p>}
                            <Button
                              block
                              color="green"
                              size="lg"
                              className="mb-3 btn-round"
                              onClick={handleForgotPassClick}
                              disabled={!getForgotButtonStatus()}
                            >
                              <span style={{ marginLeft: 8, marginRight: 8}}>Enviar email</span>
                              {loading ? (
                                <Spinner
                                  size="sm"
                                  color="light"
                                />
                              ) :  null }
                          
                            </Button>
                          </CardFooter>
                    </> : <>
                    
                    <a style={{ fontWeight: 'bold'}} >Email enviado. Fijarse en Spam</a>.
                    </> }
                </Card>
              </div>
        )}

            <div className="login-page">
              <Container>
                <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                  <Form>
                    <Card className="card-login">
                      <CardHeader>
                        <div className="logo-container">
                          <img src={nowLogo} alt="now-logo" />
                        </div>
                      </CardHeader>

                      <CardBody>
                        <InputGroup
                          className={usernameFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Username"
                            onFocus={(e) => setUsernameFocus(true)}
                            onBlur={(e) => setUsernameFocus(false)}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </InputGroup>
                        <InputGroup
                          className={passwordFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Password..."
                            onFocus={(e) => setPasswordFocus(true)}
                            onBlur={(e) => setPasswordFocus(false)}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </InputGroup>
                        <div style={{ color: 'gray', textAlign: 'right', fontSize: 12, marginBottom: 12 }}>
                          <a style={{ fontWeight: 'normal', cursor: "pointer" }} onClick={showForgotPassDialog}>¿Olvidaste la contraseña? </a>.
                        </div>
                        {showError && <p style={{ color: 'red', textAlign: 'center' }}>{errorMsg}</p>}
                      </CardBody>

                      <CardFooter>
                        <Button
                          block
                          color="green"
                          size="lg"
                          className="mb-3 btn-round"
                          onClick={keycloakLogin}
                          disabled={!getButtonStatus()}
                        >
                          
                          <span style={{ marginLeft: 8, marginRight: 8}}>Empower my data!</span>
                          {loading ? (
                            <Spinner
                              size="sm"
                              color="light"
                            />
                          ) :  null }
                      
                        </Button>
                        <div style={{ color: 'gray', textAlign: 'center', fontSize: 12 }}>
                          ¿Aún no tenés cuenta? <a style={{ fontWeight: 'bold', cursor: "pointer" }} onClick={handleRegisterClick}>Registrate</a>.
                        </div>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Container>
            </div>
          </div>
          <div
            className="full-page-background"
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          />
    </>
  );
}

export default LoginPage;
