import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import ProtectedRoute from "routes/protectedRoute";

import "assets/css/components/header.css";

import { AuthProvider } from "providers/authProvider2";
import UserLayout from "layouts/User"
import AdminRoute from "routes/adminRoute";
import AuthLayout from "layouts/Auth";
import SettingsPage from "views/Pages/SettingsPage";

const App = () => {
  return (
      <BrowserRouter basename="/">
        <Routes>
          <Route path="*" element={<Navigate to="/auth/login-page" replace />} />

          <Route path="/auth/*" element={<AuthLayout />} />
          <Route element={<ProtectedRoute redirectPath="/auth/login-page" />}>
            <Route path="/user/*" element={<UserLayout />} />
          </Route>

          <Route element={<AdminRoute redirectPath="/auth/login-page" />}>
            <Route path="/admin/settings-page" element={<SettingsPage />} />
          </Route>
          
        </Routes>
      </BrowserRouter>
  );
};

export default App;