import axios from 'axios';
import { useAuth } from 'providers/authProvider2';
import qs from 'qs';

const apiEndpoint = "https://keycloak.watiobot.ai/realms/watiobot-realm"
const userApiEndpoint = "https://users-api.watiobot.ai"

const api = axios.create({
  baseURL: apiEndpoint,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  withCredentials: true
});

const userApi = axios.create({
  baseURL: userApiEndpoint,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

//TOKEN 
export const getToken = async (username, password, saveTokenToSessionStorage) => {
    const data = {
      client_id: 'watiobot-client',
      username: username,
      password: password,
      client_secret: 'CKoVRf9k7mqQ2MAi0MoL4e6xq512IJmB',
      grant_type: 'password',
      scope: 'openid'
    };
  
    try {
        console.log("Sending request for token with params:", username, password);
        const response = await api.post('/protocol/openid-connect/token', qs.stringify(data));
        saveTokenToSessionStorage(response.data);
        return response.data;   
    } catch (error) {
        console.log("ERROR", error);
      throw error.response.data;
    }
  };

  //GET USER 
  export const getUserInfo = async (accessToken) => {
    try {
      if (!accessToken) {
        throw new Error('Access token not found');
      }
  
      console.log("Sending request for user info with access token");
      
      const response = await api.get('/protocol/openid-connect/userinfo', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      return response.data;
    } catch (error) {
      console.log("ERROR", error);
      throw error;
    }
  };

  //REGISTER USER
  export const registerUser = async (username, email, password) => {
    const body = {
      username: username,
      email: email,
      password: password,
    };

    try {
      const response = await userApi.post('/register', body);
      return response.data;
    } catch (error) {
      console.log("ERROR", error);
      if(error.response != null && error.response.data != null && error.response.data.detail){
        throw error.response.data.detail;
      } else {
        throw "An error has occurred"
      }
    }
  };

  //Logout 
  export const logoutUser = async () => {
    try {
      const refreshToken = sessionStorage.getItem('refresh_token');
      if (!refreshToken) {
        throw new Error('Access token not found');
      }

      const data = {
        client_id: 'watiobot-client',
        client_secret: 'CKoVRf9k7mqQ2MAi0MoL4e6xq512IJmB',
        refresh_token: refreshToken,
        scope: 'openid'
      };
     
      console.log("Sending request for logout");
      const response = await api.post('/protocol/openid-connect/logout', qs.stringify(data));
      return response.status == 204; 
    } catch (error) {
      console.log("ERROR", error);
      throw error;
    }
  };

  //FORGOT PASSWORD 
  export const forgotPassword = async (email) => {
    const body = {
      email: email,
    };

    try {
        console.log("Sending request for forgot password with params:", body);
        const response = await userApi.post('/forgot-password', body);
        return response.data;   
    } catch (error) {
      console.log("ERROR", error);
      if(error.response != null && error.response.data != null && error.response.data.detail){
        throw error.response.data.detail;
      } else {
        throw "An error has occurred"
      }
    }
  };