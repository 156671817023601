import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getUserInfo } from "utils/authApiManager.js";
import { useAuth } from 'providers/authProvider2';


const AdminRoute = ({ redirectPath }) => {
  const { isUserAuthenticated, currentUser } = useAuth();

  const isAdmin = () => {
    if (!Array.isArray(currentUser.group)) {
      return false;
    }
    return currentUser.group.includes("/watiobot-admin");
  };

  if (!isUserAuthenticated || !isAdmin() ) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default AdminRoute;