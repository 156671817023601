/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.2
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Label,
  Button,
  Spinner
} from "reactstrap";

// core components
import bgImage from "assets/img/bg16.jpg";
import "assets/css/screens/register-page.css";
import { isEmailValid, isPasswordValid, isUsernameValid } from '../../utils/validations.js';
import { registerUser } from "utils/authApiManager.js";
import { useNavigate } from 'react-router-dom';

function RegisterPage() {
  const navigate = useNavigate();
  const [firstnameFocus, setfirstnameFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [emailFocus, setemailFocus] = React.useState(false);

  const [firstName, setFirstName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');
  const [tycCheck, setTycCheck] = React.useState(false);

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  React.useEffect(() => {
    setShowError(false);
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  }, []);

  const register = async () => {
    setLoading(true);
    try {
      const register = await registerUser(firstName, userEmail, password);
      setShowSuccessDialog(true);
    } catch (error) {
      setErrorMsg(error);
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  function getButtonStatus() {
    const first = isUsernameValid(firstName);
    const pass = isPasswordValid(password);
    const email = isEmailValid(userEmail);
    const checkbox = tycCheck;
    return first && pass && email && tycCheck;
  }

  const closeModal = async () => {
    setShowSuccessDialog(false);
    navigate("/auth/login-page");
  };

  return (
    <>
      <div className="content">
        {showSuccessDialog && (
              <div className="overlay" style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%', 
                backgroundColor: 'rgba(0, 0, 0, 0.2)', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                zIndex: 10
              }}>
                <div style={{ 
                  backgroundColor: 'white', 
                  border: '0px solid gray', 
                  borderRadius: '10px', 
                  padding: '20px', 
                  textAlign: 'center',
                  width: 300
                }}>
                  <p style={{ color: 'black', marginTop: 22, marginBottom: 0, fontWeight: 'bold'}}> Usuario creado </p>
                  <p style={{ color: 'gray', marginTop: 12, marginBottom: 22}}>Por favor, mire su casilla de correo para terminar la registración</p>
                  <Button
                        color="green"
                        size="lg"
                        className="btn-round"
                        onClick={closeModal}
                        disabled={false}
                      > Okey
                  </Button>

                </div>
              </div>
        )}
        <div className="register-page">
          <Container>
            <Row className="justify-content-center">
              <Col lg={4} md={8} xs={12}>
                <Card className="card-signup">
                  <CardHeader className="text-center">
                    <CardTitle tag="h4">Register</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <InputGroup
                        className={firstnameFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Username"
                          onFocus={(e) => setfirstnameFocus(true)}
                          onBlur={(e) => setfirstnameFocus(false)}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={emailFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          color="green"
                          type="email"
                          placeholder="Email"
                          onFocus={(e) => setemailFocus(true)}
                          onBlur={(e) => setemailFocus(false)}
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={passwordFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Password"
                          onFocus={(e) => setPasswordFocus(true)}
                          onBlur={(e) => setPasswordFocus(false)}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                      <FormGroup className="tyc" check>
                        <Label check>
                          <Input type="checkbox" onChange={(e) => setTycCheck(e.target.checked)}/>
                          <span className="form-check-sign" />
                          <div>
                            I agree to the{" "}
                            <a href="#something">terms and conditions</a>.
                          </div>
                        </Label>
                      </FormGroup>
                    </Form>
                  </CardBody>
                  {showError && <p style={{ color: 'red', textAlign: 'center' }}>{errorMsg}</p>}
                  <CardFooter className="text-center">
                    <Button
                      color="green"
                      size="lg"
                      className="btn-round"
                      onClick={register}
                      disabled = {!getButtonStatus()}
                    >
                      <span style={{ marginLeft: 8, marginRight: 8}}>Get Started</span>
                        {loading ? (
                          <Spinner
                            size="sm"
                            color="light"
                          />
                        ) :  null }
                      
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      />
    </>
  );
}

export default RegisterPage;
