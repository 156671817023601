/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.2
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

function Footer(props) {
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid}>
        <nav>
          <ul>
            <li>
              <a
                href="https://energygreendata.com"
                className="mr-4-px"
                target="_blank"
              >
                Energy Green Data
              </a>
            </li>
            <li>
              <a
                href="https://energygreendata.com/empresa/"
                className="mr-4-px"
                target="_blank"
              >
                About the Company
              </a>
            </li>
            <li>
              <a
                href="https://energygreendata.com/contacto-chat/"
                target="_blank"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright">
          &copy; {1900 + new Date().getYear()}, Designed by{" "}
          <a href="https://enerfygreendata.com" target="_blank">
            Energy Green Data
          </a>
          . Coded by{" "}
          <a
            href="https://www.whitestorm.es"
            target="_blank"
          >
            WhiteStorm Studios
          </a>
          .
        </div>
      </Container>
    </footer>
  );
}

Footer.defaultProps = {
  default: false,
  fluid: false,
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
