import React, { createContext, useContext, useState, useEffect } from 'react';

const getUserInitialState = () => {
  const currentUser = sessionStorage.getItem("currentUser");
  return currentUser ? JSON.parse(currentUser) : null
}

// Create context for authentication
const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(() => sessionStorage.getItem('access_token'));
  const [refreshToken, setRefreshToken] = useState(() => sessionStorage.getItem('refresh_token'));
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(!!sessionStorage.getItem('access_token'));
  const [currentUser, setCurrentUser] = useState(getUserInitialState);

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    setIsUserAuthenticated(!!token);
  }, [accessToken]);

  useEffect(() => {
    sessionStorage.setItem("currentUser", JSON.stringify(currentUser))
  }, [currentUser])


  const saveTokenToSessionStorage = (token) => {
    sessionStorage.setItem('access_token', token.access_token);
    sessionStorage.setItem('refresh_token', token.refresh_token);
    setAccessToken(token);
    setIsUserAuthenticated(true);
  };

  const clearTokenFromSessionStorage = () => {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    setAccessToken(null);
    setIsUserAuthenticated(false);
  };

  const saveUserInfo = (user) => {
    setCurrentUser({ "group": user.group_membership, "username": user.preferred_username });
  };
  
  return (
    <AuthContext.Provider
      value={{
        accessToken,
        saveTokenToSessionStorage,
        clearTokenFromSessionStorage,
        isUserAuthenticated,
        saveUserInfo,
        refreshToken,
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the authentication context
export const useAuth = () => {
  return useContext(AuthContext);
};